

















































import SuggestedDocumentModal from '@/components/form/SuggestedDocumentModal.vue';

import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { SuggestedDocumentType } from '@/jbi-shared/util/document.utils';
import RelatedDocSearchResultItem from '@/components/editor/SectionEditor/RelatedDocsSectionEditor/RelatedDocSearchResultItem.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: { RelatedDocSearchResultItem, SuggestedDocumentModal }
})
export default class StagingRelatedDocForm extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop()
  public isRp!: boolean;
  @Prop()
  public isEs!: boolean;
  @Prop()
  public currentDocNodes!: string[];
  @Prop()
  public currentDocAuthors!: string[];
  @Prop()
  public currentDocumentId!: number;
  @Prop(String)
  public suggestInput?: string;
  @Prop()
  public dirtyRelatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];

  public selected: DocumentIndexingPayload[] = [];

  get SuggestedDocumentType() {
    return SuggestedDocumentType;
  }

  public handleToggle(doc: DocumentIndexingPayload) {
    const selected = this.isSelected(doc);
    if (selected) {
      this.selected = this.selected.filter(
        ({ documentId }) => documentId !== doc.documentId
      );
    } else {
      // Can only check one when edit. Clear prior selected
      if (this.suggestInput && this.selected.length > 0) {
        this.selected = [];
      }
      this.selected.push(doc);
    }
  }

  public handleSubmit(selected: DocumentIndexingPayload[]) {
    this.suggestInput
      ? this.$emit('edit', selected)
      : this.$emit('add', selected);
    this.$emit('close');
  }

  public isSelected(doc: DocumentIndexingPayload) {
    const selected = this.selected.some((s) => s.documentId === doc.documentId);
    return selected;
  }
}
