














import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { isEqual } from 'lodash';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { StagingEditorCommonMixin } from '@/views/StagingArea/mixins/staging-editor-common.mixin';
import StagingRelatedDocForm from '@/views/StagingArea/components/SectionEditor/RelatedDocEditor/StagingRelatedDocForm.vue';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';

@Component({
  components: {
    StagingRelatedDocForm,
    ListSectionValueEditor
  }
})
export default class StagingRelatedDocsSectionValueEditor extends mixins(
  ViewModeMixin,
  StagingEditorCommonMixin
) {
  @Prop()
  public link!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0];
  @Prop(Number) public index!: number;
  @Prop(Boolean) public notEditable!: boolean;
  @Prop()
  public currentDocNodes!: string[];
  @Prop()
  public currentDocAuthors!: string[];
  @Prop()
  public currentDocumentId!: number;

  get linkHtml() {
    const { href } = this.$router.resolve({
      name: 'previewLatestPublishedVersion',
      params: {
        documentId: String(this.link.relatedDocs.id)
      }
    });
    if (this.link.relatedDocs.id) {
      return `<a href='${href}' target="_blank">${this.link.relatedDocs.title}</a>`.trim();
    } else {
      return `${this.link.relatedDocs.title}`;
    }
  }

  get isInvalidDocLink() {
    return !this.link.relatedDocs.id;
  }

  public openEditForm() {
    const modalTitle = this.isRp
      ? 'Supporting Evidence Summaries'
      : 'Related JBI Evidence Summaries';
    this.$buefy.modal.open({
      parent: this,
      component: StagingRelatedDocForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        isRp: this.isRp,
        isEs: this.isEs,
        suggestInput: this.link.relatedDocs.title,
        link: this.link,
        currentDocNodes: this.currentDocNodes,
        currentDocAuthors: this.currentDocAuthors,
        currentDocumentId: this.currentDocumentId,
        dirtyRelatedDocs: this.dirtyRelatedDocs
      },
      events: {
        edit: (selected: DocumentIndexingPayload[]) => {
          const newRelatedDoc = selected.map(
            (s) =>
              ({
                relatedDocs: {
                  id: s.documentId,
                  title: s.title
                }
              } as FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0])
          );

          const updatedRelatedDocs = [...this.dirtyRelatedDocs];
          updatedRelatedDocs[this.index] = newRelatedDoc[0];

          this.$emit('update:dirtyRelatedDocs', updatedRelatedDocs);
        }
      }
    });
  }

  public deleteMe() {
    this.dirtyRelatedDocs = this.dirtyRelatedDocs.filter(
      (rd) => !isEqual(rd, this.link)
    );
  }
}
