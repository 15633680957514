

































































import { Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import StagingRelatedDocsSectionValueEditor from '@/views/StagingArea/components/SectionEditor/RelatedDocEditor/StagingRelatedDocsSectionValueEditor.vue';
import StagingRelatedDocForm from '@/views/StagingArea/components/SectionEditor/RelatedDocEditor/StagingRelatedDocForm.vue';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';
import { textToOps } from '@/utils/quill-delta.util';
import {
  PendingDocumentError,
  PendingSectionError
} from '@/jbi-shared/types/document.types';

@Component({
  components: {
    draggable,
    StagingRelatedDocsSectionValueEditor,
    StagingRelatedDocForm,
    SectionTitleEditor,
    SectionError
  }
})
export default class StagingRelatedDocsSectionEditor extends mixins(
  ViewModeMixin
) {
  public drag = false;

  @Prop()
  public isRp!: boolean;
  @Prop()
  public isEs!: boolean;
  @Prop()
  public dirtyRelatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];
  @Prop()
  public sourceEvidenceSummaryLink!:
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0]
    | undefined;
  @Prop()
  public currentDocNodes!: string[];
  @Prop()
  public currentDocAuthors!: string[];
  @Prop()
  public currentDocumentId!: number;

  private pendingRelatedDocError: PendingDocumentError[] = [];

  public openAddNewModal() {
    const modalTitle = this.sectionTitle;
    this.$buefy.modal.open({
      parent: this,
      component: StagingRelatedDocForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        isRp: this.isRp,
        isEs: this.isEs,
        currentDocNodes: this.currentDocNodes,
        currentDocAuthors: this.currentDocAuthors,
        currentDocumentId: this.currentDocumentId,
        dirtyRelatedDocs: this.dirtyRelatedDocs
      },
      events: {
        add: (selected: DocumentIndexingPayload[]) => {
          const newRelatedDoc = selected.map(
            (s) =>
              ({
                relatedDocs: {
                  id: s.documentId,
                  title: this.isEs
                    ? `${s.uniquePublicationId}-${s.title}`
                    : s.title
                }
              } as FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0])
          );
          const updatedRelatedDocs = [
            ...this.dirtyRelatedDocs,
            ...newRelatedDoc
          ];
          this.$emit('update:dirtyRelatedDocs', updatedRelatedDocs);
        }
      }
    });
  }

  get links() {
    return this.dirtyRelatedDocs;
  }
  set links(arr) {
    this.$emit('update:dirtyRelatedDocs', arr);
  }

  get textToOps() {
    return textToOps;
  }

  get sectionTitle() {
    return this.isRp
      ? 'Supporting Evidence Summaries'
      : 'Related JBI Evidence Summaries';
  }

  get hasInvalidRelatedDoc() {
    let hasError = false;

    for (const i in this.dirtyRelatedDocs) {
      if (!this.dirtyRelatedDocs[i].relatedDocs.isValid) {
        hasError = true;
        this.pendingRelatedDocError = [
          {
            code: PendingSectionError.SECTION_MISSING_INFO,
            message: `Missing information in ${this.sectionTitle}`
          }
        ];
        break;
      }
    }
    return hasError;
  }
}
